.share {
  .likely__widget {
    display: flex;
    align-items: center;
    font-family: $font-family;
    font-variation-settings: "LXND" 0;
    font-size: 3vw;
    text-shadow: none;
    margin: 0;
    padding: 1.25vw 0.1vw;
    border-radius: 0;
    transition: all 0.1s;
    background: rgba(#fff, 0);

    &:hover {
      padding-left: 2vw;
      text-shadow: none;
      color: #300095;
      fill: #300095;
      background-color: rgba(#fff, 1);
      font-variation-settings: "LXND" 100;
    }
  }

  .likely__icon {
    width: 3vw;
    height: 3vw;
    flex-shrink: 0;
  }

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .likely__button {
    padding: 1.15vw 0vw 1.15vw 1.5vw;
  }

  .likely__counter {
    background-image: none;
    padding-right: 1.5vw;
    &:not(:empty):before {
      content: "· ";
      padding-left: 0.7vw;
    }
  }

  .likely__widget {
    height: 100%;
  }
}

@media (max-width: $mobile-size) {
  .share .likely__widget {
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-left: 6px;
    font-size: 6vw;

    .likely__icon {
      width: 5vw;
      height: 5vw;
    }

    .likely__button {
      padding-left: 2vw;
    }
  }
}
