.slide {
  padding-top: 100vh;
  padding-bottom: 100vh;
  box-sizing: border-box;
  position: relative;

  &__img {
    height: 100vh;
    width: 100%;
    transition-timing-function: $bezier;
    transform-origin: 0 0;
    transition: transform $images-transition-duration,
      opacity $images-transition-duration;
    will-change: opacity, transform;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    object-fit: contain;
  }

  &__image {
    z-index: -1;
    position: relative;
  }

  &__image_ratio {
    position: relative;
  }

  &__content {
    padding: $slide-content-padding $slide-content-padding 0;
    background-color: transparent;
    position: relative;
  }

  &__header {
    font-size: 10vw;
    line-height: 0.95;
    box-sizing: border-box;
    will-change: transform;
  }

  &__description {
    padding-left: 0.9vw;
    font-size: 6vw;
    line-height: 1.1;
    padding-top: 3.5vw;
    box-sizing: border-box;
    max-width: 100%;
  }

  .half-space {
    font-size: 50%;
  }

  &__share,
  &__actions {
    margin-top: 3.5vw;
    margin-left: 1.2vw;
  }

  &__share {
    margin-bottom: 1vw;
  }

  &__main-image {
    margin-top: -3vw;
    margin-left: -$slide-content-padding;
    margin-right: -$slide-content-padding;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
    .photo-signature {
      position: absolute;
      right: 1vw;
      bottom: 1vw;
      color: rgba(#7287fc, 0.35);
      font-size: 1.25vw;
      a {
        color: rgba(#7287fc, 0.35);
        text-decoration: none;
        border-bottom: 3px solid rgba(#7287fc, 0.3);
        transition: all 0.15s ease;
        &:hover {
          color: rgba(#7287fc, 0.6);
          border-bottom-color: rgba(#7287fc, 0.6);
        }
      }
    }
  }

  &[data-slide="preview"] {
    .slide__content {
      padding-bottom: 0;
      position: relative;
      z-index: 1;
    }
    .main-header {
      font-size: 10.4vw;
    }
  }

  &[data-slide="all"] {
    padding-top: 50vh;
    padding-bottom: 50vh;
  }

  &[data-slide="art"] {
    min-height: 100vh;
    .slide__content {
      padding-bottom: 0;
    }
  }

  &[data-slide="lines-grid"] {
    .slide__image {
      opacity: 0.75;
    }
  }

  &[data-slide="titles-grid"] {
    .slide__image {
      opacity: 1;
    }
  }

  &[data-slide="lines-grid"],
  &[data-slide="titles-grid"],
  &[data-slide="future"] {
    .slide__img {
      opacity: 0;
    }
  }

  &[data-slide="lines-grid"],
  &[data-slide="titles-grid"],
  &[data-slide="airports"] {
    .slide__header {
      padding-bottom: 5.5vw;
    }
  }

  &[data-slide="art"],
  &[data-slide="preview"],
  &[data-slide="titles"],
  &[data-slide="lines"],
  &[data-slide="lines-grid"],
  &[data-slide="titles-grid"],
  &[data-slide="airports"],
  &[data-slide="future"] {
    .slide__content {
      &:after {
        content: "";
        background-image: $slide-background-image;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        z-index: -1;
        opacity: 0.9;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &[data-slide="legend"] {
    .slide__content {
      padding-left: 38%;
    }
  }

  &[data-slide="future"] {
    padding-top: 150vh;
    padding-bottom: 50vh;
    .slide__content {
      padding-bottom: 5vw;
    }
    .actions__item {
      margin: 4vw 0 0 0.6vw;
    }
  }
}

[data-current-slide="preview"] [data-slide="airports"] {
  z-index: -2;
}

[data-current-slide="preview"] [data-slide="lines"] {
  visibility: hidden;
}

@media (max-width: $mobile-size) {
  .slides .slide {
    padding-top: 15vw;
    padding-bottom: 0;
    &:first-child {
      padding-top: 0;
      .slide__header {
        padding-bottom: 0;
        letter-spacing: -0.4vw;
      }
    }

    &__header {
      line-height: 1.1;
      letter-spacing: -0.4vw;
    }

    &__content {
      box-sizing: border-box;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
      background-image: none;
      padding-top: 4vw;
      padding-left: $mobile-slide-content-padding;
      padding-right: $mobile-slide-content-padding;
      padding-bottom: 0;
      &:after {
        content: none !important;
      }
    }

    &__description {
      max-width: 100%;
      line-height: 1.3;
    }

    &__share {
      margin-left: 2.6vw;
    }

    &__image {
      margin-left: -$mobile-slide-content-padding;
      margin-right: -$mobile-slide-content-padding;
      overflow: hidden;
      z-index: 1;
    }

    &__main-image {
      margin-left: -$mobile-slide-content-padding;
      margin-right: -$mobile-slide-content-padding;
      .photo-signature {
        font-size: 2vw;
        a {
          border-bottom-width: 1.5px;
        }
      }
    }

    &__img {
      position: absolute;
      height: 100%;
      transform: scale(1);
      opacity: 1;
    }

    &[data-slide="preview"],
    &__image_ratio {
      &:before {
        content: "";
        background-image: url(../assets/images/map.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.21;
        display: block;
        height: 0;
        padding-bottom: 70%;
      }
    }

    &[data-slide="preview"] {
      &:before {
        opacity: 1;
      }
      .slide__img {
        display: none;
      }
    }

    &[data-slide="lines"] {
      padding-top: 0;
    }

    &[data-slide="preview"],
    &[data-slide="lines-grid"],
    &[data-slide="titles-grid"] {
      .slide__img {
        opacity: 1;
      }
    }

    &[data-slide="lines-grid"] {
      .slide__image_ratio:before {
        opacity: 0.4;
      }
    }

    &[data-slide="art"] {
      .slide__content {
        display: flex;
        flex-direction: column;
      }

      .art {
        order: 2;
      }
    }

    &[data-slide="legend"] {
      .slide__content {
        padding-left: $mobile-slide-content-padding;
      }
    }

    &[data-slide="all"],
    &[data-slide="badges"] {
      display: none;
    }

    &[data-slide="future"] {
      .slide__img {
        opacity: 1;
      }
      .slide__image {
        height: auto;
      }
      .slide__image_ratio {
        &:before {
          background: none;
          padding-bottom: 95%;
        }
      }
      .actions__item {
        margin-bottom: 2vw;
      }
    }
  }

  .legend {
    display: none;
  }
}
