.font-compare {
  text-align: right;
  &__picture {
    width: 70%;
    height: auto;
    max-height: 80vh;
    margin: 5vw 0;
  }
}

@media (max-width: $mobile-size) {
  .font-compare {
    display: none;
  }
}
