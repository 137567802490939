.page__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  transition: filter 0.3s;
}

[data-current-slide="preview"] .page__bg {
  filter: hue-rotate(0deg);
}

[data-current-slide="all"] .page__bg {
  filter: hue-rotate(0deg);
}

[data-current-slide="lines"] .page__bg {
  filter: hue-rotate(-30deg) brightness(70%);
}

.slide[data-slide="lines"] .slide__content:after {
  filter: hue-rotate(-30deg) brightness(50%);
}

[data-current-slide="lines-grid"] .page__bg,
.slide[data-slide="lines-grid"] .slide__content:after {
  filter: hue-rotate(-60deg);
}

[data-current-slide="titles"] .page__bg,
.slide[data-slide="titles"] .slide__content:after {
  filter: hue-rotate(-90deg);
}

[data-current-slide="titles-grid"] .page__bg,
.slide[data-slide="titles-grid"] .slide__content:after {
  filter: hue-rotate(-60deg);
}

[data-current-slide="art"] .page__bg,
.slide[data-slide="art"] .slide__content:after {
  filter: hue-rotate(-15deg);
}

[data-current-slide="airports"] .page__bg,
.slide[data-slide="airports"] .slide__content:after {
  filter: hue-rotate(0deg);
}

[data-current-slide="legend"] .page__bg {
  filter: hue-rotate(15deg);
}

[data-current-slide="future"] .page__bg,
.slide[data-slide="future"] .slide__content:after {
  filter: hue-rotate(0deg) saturate(200%);
}

[data-current-slide="badges"] .page__bg {
  filter: hue-rotate(0deg) brightness(70%);
}
