$border-size: 0.6vw;

.actions {
  display: flex;
  flex-direction: column;
  &__item {
    align-self: flex-start;
    position: relative;
    width: auto;
    display: inline-block;
    text-align: left;
    font-size: 6.2vw;
    line-height: 1.3;
    color: #220095;
    background: #fff;
    padding: 1.4vw 4vw 2vw;
    margin: 0 0 3vw -1vw;
    overflow: hidden;
    z-index: 1;
    text-decoration: none;
    text-transform: uppercase;
    // border: $border-size solid #fff;
    border-radius: 10vw;
    font-variation-settings: "LXND" 0;
    transition: all 0.12s;
    &:hover {
      color: #ffd600;
      font-variation-settings: "LXND" 65;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $mobile-size) {
  $mobile-border-size: 0.85vw;

  .actions {
    &__item {
      font-size: 6.5vw;
      padding: 1.4vw 5vw 2vw;
      margin: 0 0 4vw -1vw;
      border-width: $mobile-border-size;
      &:last-child {
        margin-top: -$mobile-border-size;
        margin-bottom: $mobile-border-size;
      }
    }
  }
}
