@import "ilyabirman-likely/release/likely.css";
@import "./_vars.scss";

@font-face {
  font-family: "Lexend";
  src: url("/assets/fonts/Lexend.ttf");
  font-display: swap;
}

body {
  margin: 0;
  background-color: rgba(0, 10, 20, 1);
  font-family: $font-family;
  color: #fff;
  overflow-x: hidden;
}

::selection {
  background: black;
}
::-moz-selection {
  background: black;
}

h1,
h2,
p {
  margin: 0;
}

@import "./actions.scss";
@import "./page.scss";
@import "./page__bg.scss";
@import "./slide.scss";
@import "./titles.scss";
@import "./legend.scss";
@import "./art.scss";
@import "./share.scss";
@import "./footer.scss";
