.legend {
  &__picture {
    width: 40%;
    height: auto;
    max-height: 80vh;
    margin: 5vw 0;
    padding: 2vw 1vw;
    background: rgba(#11002f, 0.3);
    border-radius: 10px;
  }
}
