.footer {
  font-size: 4.9vw;
  display: flex;
  flex-direction: column;
  padding: 6vw $slide-content-padding 5vw;
  position: relative;
  overflow: hidden;
  z-index: 2;
  &:after {
    content: "";
    background-image: $slide-background-image;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__description {
    padding: 0 0 5vw 0.9vw;
    box-sizing: border-box;
    max-width: 100%;
  }

  &__actions {
    padding-left: 0vw;
    margin-left: 1.2vw;
  }

  &__share {
    margin-top: 3vw;
    margin-left: 1.2vw;
    align-self: flex-start;
  }

  &__authors {
    font-size: 3vw;
    line-height: 1.5;
    margin-top: 3vw;
    margin-left: 1.1vw;
  }

  &__author,
  &__description {
    a {
      color: #fff;
      text-decoration: none;
      border-bottom: solid rgba(#fff, 0.3);
      font-variation-settings: "LXND" 0;
      transition: all 0.2s $bezier;
      &:hover {
        border-bottom: solid rgba(#fff, 1);
        font-variation-settings: "LXND" 40;
      }
    }
  }

  &__author {
    a,
    a:hover {
      border-bottom-width: 6px;
    }
  }

  &__description {
    a,
    a:hover {
      border-bottom-width: 9px;
    }
  }

  &__picture-ratio {
    position: relative;
    padding-bottom: 67.2%;
  }

  &__picture {
    position: absolute;
    height: 100%;
  }
}

@media (max-width: $mobile-size) {
  .footer {
    font-size: 5.1vw;
    line-height: 1.5;
    padding-left: $mobile-slide-content-padding;
    padding-right: $mobile-slide-content-padding;
    &:after {
      content: none;
    }
    a {
      border-bottom-width: 3px !important;
    }
    &__authors {
      font-size: 3.5vw;
      line-height: 1.8;
      margin-top: 10vw;
      a {
        border-bottom-width: 2.5px !important;
      }
    }
    &__actions {
      padding-top: 3vw;
      padding-bottom: 3vw;
    }
  }
}
