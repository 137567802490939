.art {
  display: flex;
  flex-direction: column;
  margin-top: 4.5vw;
  margin-left: -$slide-content-padding;
  margin-right: -$slide-content-padding;
  padding: 0 0.25vw 0.25vw;
  color: transparent;
  &__picture {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    box-sizing: border-box;
    padding: 0.25vw;
  }
  &__row1,
  &__row2 {
    display: flex;
    flex-direction: row;
  }
  &__row1 {
    .art__picture:first-child {
      width: 33.4%;
    }
    .art__picture:last-child {
      width: 66.6%;
    }
  }
  &__row2 {
    .art__picture:first-child {
      width: 66.6%;
    }
    .art__column {
      width: 33.4%;
      display: flex;
      flex-direction: column;
      .art__picture {
        width: 100%;
        height: 40vh;
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .art {
    display: block;
    height: auto;
    margin-left: 0;
    margin-right: 0;
    &__img {
      height: auto;
    }
    &__picture {
      height: 40vh;
    }
    &__row2 {
      .art__column {
        .art__picture {
          height: 20vh;
        }
      }
    }
  }
}
