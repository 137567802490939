.page {
  &__languages {
    position: fixed;
    top: 0;
    right: 10px;
    display: flex;
    z-index: 999;
    margin: 0.5vw;
  }

  &__language {
    padding: 0.5vw 0.55vw;
    font-size: 18px;
    color: #fff;
    cursor: default;
    transition: all 0.3s $bezier;
    text-decoration: none;
    background-color: transparent;
    opacity: 0.3;
    &_active {
      opacity: 0.7;
    }
    &:not(&_active) {
      cursor: pointer;
      &:hover {
        background-color: rgba(#fff, 0.2);
        opacity: 1;
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .page {
    &__languages {
      right: 0;
    }
    &__language {
      padding: 1vw;
    }
  }
}
